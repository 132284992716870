<template>
	<component v-for="(section, index) in content" :key="index" :is="section.name" :data="section"></component>
</template>

<script>
	import PageContent from '@/assets/pages/';

	export default {
		created() {
			this.content = PageContent[this.$route.name].content;
		},

		mounted() {
			this.$functions.sectionPadding();
		}
	}
</script>